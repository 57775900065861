(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Accordion for FAQs (jQuery)
        $('.accordion dt.active').next().slideDown()

        $('.accordion').on('click', 'dt', function () {
            $('.accordion dd').slideUp();

            if (!$(this).hasClass('active')) {
                // remove active class from any others that might be active
                $('.accordion dt.active').removeClass('active');
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
            }
        });


        $('a.readmore-toggle').click(function(e){
            e.preventDefault();

            if($(this).attr('href') == '#'){
                $('.section-seocontent').show();
                $('html,body').animate({scrollTop:$('.section-seocontent').offset().top - $('.site-header').outerHeight()}, 600);
            }else{
                $(this).hide();
                $(this).parent().next('.readmore-container').slideDown(600);
            }

        });

        $('a.readless-toggle').click(function(e){
            e.preventDefault();

            if($(this).attr('href') == '#'){
                $('a.readmore-toggle').show();
                $('.section-seocontent').slideUp(600);
            }else{
                $('a.readmore-toggle').show();
                $(this).closest('.readmore-container').slideUp(600);
            }

        });


        $('a[href="#searches"]').click(function(e){
            e.preventDefault();

            // dont use "this" as there's several we need to change
            if($(this).hasClass('revealed')){
                $('a[href="#searches"]').text('Popular Searches');
                $('a[href="#searches"]').removeClass('revealed');
                $('.seo-footer').slideUp(600);
            }else{
                $('a[href="#searches"]').addClass('revealed');
                $('a[href="#searches"]').text('Hide Popular Searches');
                $('.seo-footer').show();
                $('html,body').animate({scrollTop:$('.seo-footer').offset().top - $('.site-header').outerHeight()},600);
            }
        });

        $('.header-hamburger').click(function(){
            $('body').toggleClass('menu-open');
        });

        var _paged = 1;
        $('#load-more').click(function(e){
            e.preventDefault();

            var $this = $(this);
            var ptype = $(this).data('type');

            $this.text('Loading...');

            _paged++;

            $.ajax({
                url: _ajaxurl,
                type: 'post',
                data: {
                    action: 'more_posts',
                    paged: _paged,
                    post_type: ptype
                }
            }).done(function(data){
                var response = JSON.parse(data);
                $('#ajax-append').append(response.html);
                $this.text('Load More Blogs');

                if(response.noneleft){
                    $this.hide();
                }
            });
        }); 

        // Inline Video Funcionality
        $(document).on('click', '.inline-video-trigger', function () {
            if ($(this).data('video-id')) {
                if ($(this).hasClass('vimeo')) {
                    var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                } else {
                    var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                }

                $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
                $(this).parent('.video-preview-container').find('.overlay').hide();
                $(this).parent('.video-preview-container').find('iframe').remove();
                $(this).parent('.video-preview-container').append(iframeHTML);
            } else {
                console.error('no video ID provided.');
            }
        });

        // Phone Concatenation Script For Tracking
        setTimeout(function () {
            $('.phone-text em').each(function () {
                var unsliced = $(this).text();
                var sliced = unsliced.slice(0, -2) + "...";
                $(this).text(sliced);
                var linked = "tel:" + unsliced.replace(/\s/g, '');
                $(this).click(function () {
                    if ($(window).width() < 1000) {
                        window.location.href = linked;
                    } else {
                        $(this).text(unsliced);
                    }
                });
            });

        }, 3000);

        // get all sliders, we need to loop them due to different settings + nav
        var swipers = document.querySelectorAll('.swiper-container:not(.control):not(.mobile)');
        swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

            // slider settings
            var options = {
                speed: 600,
                loop: true,
                slidesPerView: 1,
                centeredSlides: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true,
                },
                navigation:{},
                thumbs:{},
                breakpoints: {
                    768 : {
                        slidesPerView: 3
                    }
                }
            };

            if(el.hasAttribute('data-lazy')){
                options.lazy = true;
                options.preloadImages = false;
            }

            if(el.hasAttribute('data-dots')){
                options.pagination = {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                };
            }


            if(el.hasAttribute('data-slides')){
                options.breakpoints = {};
                options.slidesPerView = parseInt(el.getAttribute('data-slides'));
            }

            if(el.hasAttribute('data-spacing')){
                options.spaceBetween = parseInt(el.getAttribute('data-spacing'));
            }

            // init nav
            options.navigation = {
                nextEl: closestSection.querySelector('.swiper-next'),
                prevEl: closestSection.querySelector('.swiper-prev')
            }

            // maybe add gallery controls to the slider
            if(controls){
                var controloptions = {
                    speed: 600,
                    loop: true,
                    slidesPerView: 2,
                    spaceBetween: 9
                };

                if(el.hasAttribute('data-lazy')){
                    controloptions.lazy = true;
                    controloptions.preloadImages = false;
                }

                options.thumbs.swiper = new Swiper(controls, controloptions);

            }

            // init slider
            new Swiper(el, options);
        });

        // mobile sliders, like logo rows etc
        // need to loop in order to get the slide count
        var mobileSwipers = document.querySelectorAll('.swiper-container.mobile');
        mobileSwipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var slideCount = el.querySelectorAll('.swiper-slide').length;

            var options = {
                speed:600,
                slidesPerView: 3,
                watchOverflow: true,
                loop: false,
                simulateTouch: false,
                pagination:{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    576 : {
                        slidesPerView: 3
                    },
                    768 : {
                        slidesPerView: 4
                    },
                    992: {
                        slidesPerView: slideCount,
                    }
                }
            }; 

            // init slider
            new Swiper(el, options);
        });
    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });

    $(document).on('click', 'a[href*="get-quote"]', function (event) {
        var target = $(this).attr('href');

        if($('#quote-form').length){
            event.preventDefault()
            $('html, body').animate({
                scrollTop: $('#quote-form').offset().top - $('.site-header').outerHeight()
            }, 1500)
        }
    });

    //  form thankyou redirects
    var wpcf7Elm = document.querySelector( '.wpcf7' );
    if(wpcf7Elm){
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            if(wpcf7Elm.querySelector('[name="redirect"]')){
                var redirect = wpcf7Elm.querySelector('[name="redirect"]').value;
                window.location.href = redirect;
            }
        }, false );
    }

    $(document).on('click', 'a[href*="#"]:not([href="#rmdiv"])', function (event) {
        event.preventDefault()
        var target = $(this).attr('href');

        if(target.indexOf('get-quote') > -1){
            if($('#quote-form').length){
                target = '#quote-form';
            }else{
                window.location.href = _offerurl;
            }
        }

        if ($(target).length) {
            $('html, body').animate({
                scrollTop: $(target).offset().top - $('.site-header').outerHeight()
            }, 1500)
        }
    });

})( jQuery );